import React from "react";
import { StyledLoader } from "./Loader.styled";

const Loader = ({ fullScreen }) => {
  return (
    <StyledLoader scale={2.5} color="lightblue">
      <div className={`loader ${fullScreen ? "loader__full-screen" : ""}`}>
        <div className="lds-roller">
          {Array(8)
            .fill("")
            .map((i, index) => (
              <div key={index} />
            ))}
        </div>
      </div>
    </StyledLoader>
  );
};

export default Loader;
