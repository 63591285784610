import React, { useState } from "react";
import { useTimer } from "react-timer-hook";

const CountdownTimer = ({ expiryTimestamp }) => {
  const [expire, setExpire] = useState("");
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => setExpire("Expire"),
  });

  const tableStyles = {
    width: 60,
    fontSize: 28,
    backgroundColor: "#555",
    color: "white",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      {expire ? (
        <div className="text-2xl">{expire}</div>
      ) : (
        <div className="timer flex" style={{ gap: 4 }}>
          <div>
            <div className="text-xl text-center">DAYS</div>
            <div style={tableStyles}>{days < 10 ? `0${days}` : days}</div>
          </div>
          <div>
            <div className="text-xl text-center">HOURS</div>
            <div style={tableStyles}>{hours < 10 ? `0${hours}` : hours}</div>
          </div>
          <div>
            <div className="text-xl text-center">MINS</div>
            <div style={tableStyles}>
              {minutes < 10 ? `0${minutes}` : minutes}
            </div>
          </div>
          <div>
            <div className="text-xl text-center">SECS</div>
            <div style={tableStyles}>
              {seconds < 10 ? `0${seconds}` : seconds}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const IdoList = ({ details }) => {
  const { name, date, link, status, date_unix } = details;

  return (
    <div className="flex shadow-lg rounded-lg bg-gray-50 mt-2 mb-1 p-4">
      <div className="flex flex-col">
        <h2 className="font-bold text-2xl">{name}</h2>
        <h3 className="text-xl">Type: {status}</h3>
        {date}
        <a
          href={link}
          target="_blank"
          className="text-blue-500"
          rel="noreferrer"
        >
          {link}
        </a>
      </div>
      <div className="ml-auto flex items-center">
        <CountdownTimer expiryTimestamp={date_unix} />
      </div>
    </div>
  );
};

const Ido = ({ data }) => {
  return (
    <>
      {data.map((i) => (
        <IdoList key={i._id} details={i} />
      ))}
    </>
  );
};

export default Ido;
