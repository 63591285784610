import React from "react";

const Header = ({ children }) => {
  return (
    <header
      className="header flex flex-column shadow-lg rounded-lg bg-gray-50 mt-2 mb-2 p-4"
      style={{ overflow: "hidden" }}
    >
      {children}
    </header>
  );
};

export default Header;
