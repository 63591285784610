import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import useCryptoStore from "../store/cryptoStore";
import useIdoStore from "../store/idoStore";
import Loader from "./Loader/Loader";
import PageWrapper from "./PageWrapper/PageWrapper";
import Table from "./Table/Table";
import Ido from "./Ido/Ido";

const App = () => {
  const loading = useCryptoStore((state) => state.loading);
  // const loading = true;
  const crypto = useCryptoStore((state) => state.crypto);
  const fetchCrypto = useCryptoStore((state) => state.fetch);
  const ido = useIdoStore((state) => state.ido);
  const fetchIdo = useIdoStore((state) => state.fetch);

  const binance = crypto.filter((coin) => coin.exchange === "Binance");
  const etoro = crypto.filter((coin) => coin.exchange === "eToro");
  const kucoin = crypto.filter((coin) => coin.exchange === "KuCoin");
  const profit = crypto.filter((coin) => coin.revenue > 0);
  const nonProfit = crypto.filter((coin) => coin.revenue < 0);

  useEffect(() => {
    fetchCrypto();
    fetchIdo();
  }, [fetchCrypto, fetchIdo]);

  return (
    <div className="bg-purple-50">
      {loading ? (
        <Loader fullScreen />
      ) : (
        <Router>
          <div className="container flex m-auto flex-col">
            <Switch>
              <Route exact path="/">
                <PageWrapper data={crypto}>
                  <Table data={crypto} />
                </PageWrapper>
              </Route>
              <Route path="/binance">
                <PageWrapper data={binance}>
                  <Table data={binance} />
                </PageWrapper>
              </Route>
              <Route path="/kucoin">
                <PageWrapper data={kucoin}>
                  <Table data={kucoin} />
                </PageWrapper>
              </Route>
              <Route path="/etoro">
                <PageWrapper data={etoro}>
                  <Table data={etoro} />
                </PageWrapper>
              </Route>
              <Route path="/profit">
                <PageWrapper data={profit}>
                  <Table data={profit} />
                </PageWrapper>
              </Route>
              <Route path="/non-profit">
                <PageWrapper data={nonProfit}>
                  <Table data={nonProfit} />
                </PageWrapper>
              </Route>
              <Route path="/ido">
                <PageWrapper data={crypto}>
                  <Ido data={ido} />
                </PageWrapper>
              </Route>
            </Switch>
          </div>
        </Router>
      )}
    </div>
  );
};

export default App;
