import React, { useState, useEffect, memo } from "react";
import "./Stats.scss";

const Stats = memo(({ data }) => {
  const [totalCost, setTotalCost] = useState([1, 2]);
  const [totalRevenue, setTotalRevenue] = useState([1, 2]);
  const [totalInvested, setTotalInvested] = useState([1, 2]);

  const calculateTotal = (input, row) => {
    return input
      .map((i) => +i[row])
      .reduce((a, b) => a + b, 0)
      .toFixed(2)
      .split(".");
  };

  useEffect(() => {
    setTotalCost(calculateTotal(data, "cost"));
    setTotalRevenue(calculateTotal(data, "revenue"));
    setTotalInvested(calculateTotal(data, "invested"));
  }, [data]);

  return (
    <div className="flex ml-auto">
      <div className="p-1 mr-6">
        <div className="text-2xl font-bold">Invested</div>
        <div>
          <span className="text-2xl">$ {totalInvested?.[0]}</span>
          <span className="text-md text-gray-600">
            .{totalInvested?.[1]}
          </span>{" "}
        </div>
      </div>

      <div className="p-1 mr-10">
        <div className="text-2xl font-bold">Profit</div>
        <div>
          <span
            className={`text-2xl ${
              totalRevenue?.[0] < 0 ? "text-red-500" : "text-green-600"
            }`}
          >
            $ {totalRevenue?.[0]}
          </span>
          <span
            className={`text-md ${
              totalRevenue?.[0] < 0 ? "text-red-300" : "text-green-300"
            }`}
          >
            .{totalRevenue?.[1]}
          </span>
        </div>
      </div>

      <div className="p-1 text-white relative z-10">
        <div
          className={`stats ${
            totalRevenue?.[0] < 0 ? "bg-red-400" : "bg-green-400"
          }`}
        />
        <div className="text-2xl font-bold relative z-10">Total Balance</div>
        <div className="relative z-10" style={{ textAlign: "right" }}>
          <span className="text-2xl">$ {totalCost?.[0]}</span>
          <span className="text-md text-white-200">.{totalCost?.[1]}</span>
        </div>
      </div>
    </div>
  );
});

export default memo(Stats);
