import React from "react";
import Navigation from "../Navigation/Navigation";
import Header from "../Header/Header";
import Stats from "../Stats/Stats";

const PageWrapper = ({ data, children }) => {
  return (
    <>
      <Header>
        <Navigation />
        <Stats data={data} />
      </Header>
      {children}
    </>
  );
};

export default PageWrapper;
