import React, { useState, useCallback, useEffect } from "react";
import "./Table.css";

const Table = ({ data }) => {
  const rows = [
    "currency",
    "amount",
    "priceBuy",
    "price",
    "invested",
    "cost",
    "revenue",
    "percent",
  ];
  const [table, setTable] = useState([]);
  const [sortedRow, setSortedRow] = useState("");
  const [isSorted, setIsSorted] = useState({ isSorted: false, row: "" });

  const sortClick = useCallback(
    (event) => {
      const row = event.target.textContent;

      setSortedRow(sortedRow === row ? "" : row);
      if (event.target.tagName === "TH") {
        const sorted = [...table.sort((a, b) => a[row] - b[row])];
        const reversed = [...table.sort((a, b) => b[row] - a[row])];

        setTable(sortedRow === row ? reversed : sorted);
        setIsSorted({ isSorted: !isSorted.isSorted, row });
      }
    },
    [table, sortedRow, isSorted.isSorted]
  );

  useEffect(() => setTable(data), [data]);

  return (
    <div className="shadow-lg rounded-lg bg-gray-50 p-1">
      <table className="table-fixed w-full ">
        <thead>
          <tr onClick={sortClick}>
            {rows.map((row) => (
              <th
                key={row}
                className={`select-none 
          ${
            isSorted.row === row
              ? `th--${isSorted.isSorted ? "up" : "down"}`
              : ""
          }`}
              >
                {row}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!!table &&
            table.map((d) => {
              return (
                <tr key={d._id}>
                  <td className="border p-0.5 flex items-start flex-col">
                    <div
                      className="text-xl font-bold flex items-center alias"
                      data-alias={d.name}
                    >
                      <img
                        src={d.logo_url}
                        style={{ width: 28, height: 28, borderRadius: "100%" }}
                        alt=""
                      />
                      <span className="pl-1.5">{d.alias}</span>
                    </div>
                  </td>
                  <td className="border p-0.5 text-center">{d.amount}</td>
                  <td className="border p-0.5 text-center">{d.priceBuy}</td>
                  <td className="border p-0.5 text-center">
                    {Number(d.price).toFixed(d.priceBuy.split("").length)}
                  </td>
                  <td className="border p-0.5 text-center">{d?.invested} $</td>
                  <td className="border p-0.5 text-center">{d?.cost} $</td>
                  <td className="border p-0.5 text-center">
                    <span
                      className={
                        d?.revenue < 0 ? "text-red-500" : "text-green-500"
                      }
                    >
                      {d?.revenue} $
                    </span>
                  </td>
                  <td className="border p-0.5 text-center">
                    <span
                      className={
                        d?.percent < 0 ? "text-red-500" : "text-green-500"
                      }
                    >
                      {d?.percent} %
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
