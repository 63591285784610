import create from "zustand";
import { api } from "../constants/api";
import axios from "axios";

const useIdoStore = create((set) => ({
  loading: true,
  ido: [],
  fetch: async () => {
    axios.get(api.urlIdo).then((res) => {
      set({ ido: res.data });
      set({ loading: false });
    });
  },
}));

export default useIdoStore;
