import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <nav className="p-1 flex items-center">
      <Link to="/" className="p-3 text-xl">
        All
      </Link>
      <Link to="/binance" className="p-3 text-xl">
        Binance
      </Link>
      <Link to="/kucoin" className="p-3 text-xl">
        KuCoin
      </Link>
      <Link to="/etoro" className="p-3 text-xl">
        eToro
      </Link>
      <Link to="/profit" className="p-3 text-xl">
        Profit
      </Link>
      <Link to="/non-profit" className="p-3 text-xl">
        Non-profit
      </Link>
      <Link to="/ido" className="p-3 text-xl">
        IDO
      </Link>
    </nav>
  );
};

export default Navigation;
