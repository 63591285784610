import create from "zustand";
import {api} from "../constants/api";
import axios from "axios";

const useCryptoStore = create((set) => ({
  loading: true,
  crypto: [],
  fetch: async () => {
    axios.get(api.urlCrypto).then(res => {
      set({crypto: res.data})
      set({loading: false});
    })
  },
}));

export default useCryptoStore;
